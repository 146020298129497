import { useState } from "react";
import logo from './logo.svg';
import './App.css';

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: process.env.REACT_APP_API_KEY,
});
const openai = new OpenAIApi(configuration);
const userPrompt = '';

function uploadImageUrl(imageUrl) {

  const accessToken = process.env.DROPBOX_ACCESS_TOKEN;

  // Next, you will need to create a Dropbox file object that represents the image you want to upload.
  // The file object should contain the file's name, MIME type, and contents.
  // In this case, the file contents will be the image URL.
  const file = {
    "path": "/image.jpg",
    "mode": "add",
    "autorename": true,
    "mute": false,
    "strict_conflict": false,
    "content_url": imageUrl
  };

  // Now you can use the Dropbox API's filesUpload method to upload the file to Dropbox.
  const apiUrl = 'https://api.dropboxapi.com/2/files/upload';
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/octet-stream'
    },
    body: JSON.stringify(file)
  };
  fetch(apiUrl, options)
    .then(response => {
      if (response.ok) {
        // The file was successfully uploaded.
        console.log('File uploaded successfully');
      } else {
        // There was an error uploading the file.
        console.error('Error uploading file');
      }
    });
}

function App() {
  const [userPrompt, setUserPrompt] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [visible, setVisible] = useState(false);
  const [cursor, setCursor] = useState('pointer');

  const changeCursor = () => {
    setCursor(prevState => {
      if(prevState === 'crosshair'){
        return 'pointer';
      }
      return 'crosshair';
    });
  }

  const generateImage = async () => {
    changeCursor(); //toggle cursor style
    const imageParameters = {
      prompt: userPrompt,
      n: 1,
      size: "512x512",
    }
    const response = await openai.createImage(imageParameters);
    const urlData = response.data.data[0].url;
    console.log(urlData);
    console.log(userPrompt);
    setImageUrl(urlData);
    setVisible(true);
    changeCursor(); //reset cursor style
  }

  const saveImage = async () => {
    alert(userPrompt);

    //uploadImageUrl(imageUrl, prompt)
    setVisible(false);
  }

  return (
    <div className="App">
      <h1>Moll-<span>AI</span></h1>
      <div>
      {
        imageUrl
          ? <img src={imageUrl} className="image" alt="ai thing" />
          : <img src={logo} className="image" alt="logo" width="640" height="640"  />
      }
      </div>
      <p>What do you want to create?</p>
      <div id="start" style={{display: visible ? 'none' : 'block'}}>
        <input id="prompt" 
          placeholder='A sunset on the Sydney Opera House'
          onChange={(e) => setUserPrompt(e.target.value)}
        />
        <button id="generate" onClick={() => generateImage()} style={{ cursor: cursor }}>Generate an image</button>
      </div>
      <div id="finish">
        <button id='save' onClick={() => saveImage()} style={{display: visible ? 'block' : 'none'}}>Save my image</button>
        <button id="retry" onClick={() => generateImage()} style={{display: visible ? 'block' : 'none' }}>I don't like it, try again!</button>
      </div>
      
    </div>
  );
}

export default App;
